<template>
  <div class="profile-page">
    <div class="top-side">
      <p class="header">My Profile</p>
       <div class="tabs">
        <a href="#" class="tab" :class="{ active: activeTab === 'profile' }" @click="activeTab = 'profile'">Profile</a>
        <a href="#" class="tab" :class="{ active: activeTab === 'billing' }" @click="activeTab = 'billing'">Billing</a>
        <a href="#" class="tab" :class="{ active: activeTab === 'accountInfo' }" @click="activeTab = 'accountInfo'">Account Info</a>
      </div>
    </div>
    
    <div class="bottom-side">
      <div v-if="activeTab === 'profile'">
        <div class="profile-header">
            <div class="profile-info">
              <div class="profile-picture">
                <span class="material-symbols-rounded">photo_camera</span>
              </div>
              <div class="profile-name">
                <p class="p-title">{{ profileData.fullName }}</p>
                <p class="p-name">{{ profileData.companyName }}</p>
                <a href="#" class="role-tag">
                  <span class="material-symbols-rounded">work</span>
                  <p class="p-role">{{ profileData.role }}</p>
                </a>
              </div>
            </div>
          </div>
          <div class="profile-details">
            <div class="profile-row">
              <div class="profile-item">
                <p>First Name</p>
                <span>{{ profileData.firstName }}</span>
              </div>
              <div class="profile-item">
                <p>Last Name</p>
                <span>{{ profileData.lastName }}</span>
              </div>
            </div>
            <div class="profile-row">
              <div class="profile-item">
                <p>Personal Email Address</p>
                <span>{{ profileData.personalEmail }}</span>
              </div>
              <div class="profile-item">
                <p>Contact No.</p>
                <span>{{ profileData.contactNumber }}</span>
              </div>
            </div>
            <div class="profile-row">
              <div class="profile-item">
                <p>Law Firm Name</p>
                <span>{{ profileData.lawFirm }}</span>
              </div>
              <div class="profile-item">
                <p>Role</p>
                <span>{{ profileData.role }}</span>
              </div>
            </div>
            <div class="profile-row">
              <div class="profile-item">
                <p>Firm Email Address</p>
                <span>{{ profileData.firmEmail }}</span>
              </div>
              <div class="profile-item">
                <p>Firm Contact No.</p>
                <span>{{ profileData.firmContact }}</span>
              </div>
            </div>
            <div class="profile-row">
              <div class="profile-item full">
                <p>Firm Address</p>
                <span>{{ profileData.firmAddress }}</span>
              </div>
            </div>
          </div>
        <div class="edit">
          <button class="edit-button" @click="editProfile">Edit Profile</button>
        </div>
      </div>

      <div v-if="activeTab === 'billing'">
        <h2>Billing Information</h2>
      </div>

      <div v-if="activeTab === 'accountInfo'">
        <!-- Password Section -->
        <div v-if="!isChangingPassword">
          <div>
            <div class="email-header">
              <span class="material-symbols-rounded">mail</span>
              <p>My Email</p>
            </div>
            
            <div class="email-info">
              <p class="label">Log In Email</p>
              <p class="email">{{ profileData.email }}</p>
            </div>
            
            <div class="password-section">
              <p class="label">Password</p>
              <button class="change-password-btn" @click="togglePasswordChange">Change Password</button>
            </div>
          </div>
        </div>

        <!-- Edit Password Section -->
        <div v-else>
          <div>
            <div class="password-header">
              <span class="material-symbols-rounded">lock</span>
              <p>My Password</p>
            </div>

            <div class="password-form">
              <div class="input-field">
                <label for="currentPassword">Current Password</label>
                <div class="password-input-wrapper">
                  <input type="password" id="currentPassword" v-model="currentPassword" placeholder="Current Password" />
                  <span class="material-symbols-rounded toggle-visibility" @click="toggleVisibility">visibility_off</span>
                </div>
              </div>

              <div class="input-field">
                <label for="newPassword">New Password</label>
                <input type="password" id="newPassword" v-model="newPassword" placeholder="New Password" />
              </div>

              <div class="input-field">
                <label for="confirmPassword">Confirm Password</label>
                <input type="password" id="confirmPassword" v-model="confirmPassword" placeholder="Confirm Password" />
              </div>

              <div class="button-group">
                <button class="cancel-btn" @click="togglePasswordChange">Cancel</button>
                <button class="update-btn" @click="updatePassword">Update Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/store/Auth.js";
export default {
  data() {
    return {
      activeTab: 'profile',
      profileData: {
        fullName: '',
        companyName: '',
        firstName: '',
        lastName: '',
        personalEmail: '',
        contactNumber: '',
        lawFirm: '',
        role: '',
        firmEmail: '',
        firmContact: '',
        firmAddress: '',
        email: ''
      },
      isChangingPassword: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      extraInfo: true,
      website: "www.example.com",
      name: JSON.parse(localStorage.userInfo).name,
      email: JSON.parse(localStorage.userInfo).email,
      form: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },

      extra_info: {
        contact_number: " ",
        firm_address_1: " ",
        firm_address_2: " ",
        firm_address_3: " ",
        company_name: JSON.parse(localStorage.userInfo).extra_info.company_name,
      },
      isEditing: false,
    }
  },
  methods: {
    editProfile() {
      alert('Edit profile clicked!');
    },
    togglePasswordChange() {
      this.isChangingPassword = !this.isChangingPassword;
    },
    updatePassword() {
      if (this.newPassword === this.confirmPassword) {
        alert('Password updated successfully');
      } else {
        alert('Passwords do not match');
      }
    },
    toggleVisibility() {
      // Handle password visibility toggle
      alert('Toggle visibility clicked!');
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    saveChanges() {
      switch("") {
        case this.extra_info.company_name:
        case this.extra_info.firm_address_1:
        case this.extra_info.contact_number:
          this.$toast.error("Please fill in all fields");
          return;
      }
      switch(null) {
        case this.extra_info.company_name:
        case this.extra_info.firm_address_1:
        case this.extra_info.contact_number:
          this.$toast.error("Please fill in all fields");
          return;
      }

      this.extra_info.firm_address_2 = this.extra_info.firm_address_2 == undefined
        ? "" : this.extra_info.firm_address_2;
      this.extra_info.firm_address_3 = this.extra_info.firm_address_3 == undefined
        ? "" : this.extra_info.firm_address_3;

      Auth.updateProfile({
        name: this.name,
        email: this.email,
        extra_info: {
          company_name: this.extra_info.company_name,
          contact_number: this.extra_info.contact_number,
          address: [
            this.extra_info.firm_address_1,
            this.extra_info.firm_address_2,
            this.extra_info.firm_address_3,
          ].join("\n"),
        },
      })
        .then((response) => {
          //
          this.$toast.success(response.data.message);

          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    savePassword() {
      Auth.changePassword(this.form)
        .then((response) => {
          this.$toast.success(response.data.message);

          this.$bvModal.hide("modal-center-change-password");
          this.form = {
            old_password: "",
            password: "",
            password_confirmation: "",
          };

          Auth.getMe().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
  // Assuming the user info is stored in localStorage as `userInfo`
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  if (userInfo && userInfo.extra_info) {
    const fullNameArray = userInfo.name.split(' ');

    // Assign firstName as the first part before the space and lastName as the rest
    const firstName = fullNameArray[0];
    const lastName = fullNameArray.slice(1).join(' '); // Handles names with multiple spaces

    this.profileData = {
      fullName: userInfo.name,
      companyName: userInfo.extra_info.company_name || '',
      firstName: firstName || '', // First name
      lastName: lastName || '',   // Last name (everything after the first space)
      personalEmail: userInfo.email || '',
      contactNumber: userInfo.extra_info.contact_number || '',
      lawFirm: userInfo.extra_info.company_name || '',
      role: userInfo.is_manager ? 'Manager' : 'User',
      firmEmail: userInfo.email || '',
      firmContact: userInfo.extra_info.contact_number || '',
      firmAddress: userInfo.extra_info.address || '',
      email: userInfo.email || ''
    };
  }
}

}
</script>
<style scoped>
/* General Page Styling */
.profile-page {
  width: 1160px;
  display: flex;
  flex-direction: column;
}
.page-content{
  padding: 0;
}
.top-side {
  height: 108px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  background: #FFFFFF;
}
.header{
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  padding-top: 15px;
}
.tabs {
  display: flex;
  gap: 20px;
  color: var(--dark-grey);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px;
}

.tab {
  text-decoration: none;
  color: var(--primary);
   padding-bottom: 8px;
}

.tab.active {
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
}

.bottom-side {
  height: 89.31%;
  padding: 40px;
}

.profile-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.profile-info {
  display: flex;
  align-items: flex-end;
}

.profile-picture {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  span{
    font-variation-settings: 'FILL' 1;
    background: #E7E7E7;
    font-size:18px;
  }
}

.profile-name {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}
.p-title{
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}
p-name{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--dark-grey);
}

.role-tag {
  display: flex;
  align-items: center;
  text-decoration: none;
  span{
    margin-right: 5px;
  }
}
.p-role{
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--status-blue) !important;
  margin: 0;
}
/* Profile Details Styling */
/* General profile details layout */
.profile-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-row {
  display: flex;
  justify-content: flex-start;
  gap: 20px; /* Set gap between each item */
}

.profile-item {
  flex: none; /* Prevent items from expanding */
  width: 33%; /* Adjust percentage width of each item */
}

.profile-item.full {
  width: 100%; /* For items that should take the full width */
}

.profile-item p {
  margin: 0;
  font-size: 14px;
  color: #A9A7A7;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.profile-item span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--neutral-black);
}


/* Edit Button Styling */
.edit {
  display: flex;
  flex-direction: start;
  margin-top: 40px;
}

.edit-button {
  background-color: var(--primary);
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-button:hover {
  background-color: darken(var(--primary), 10%);
}
</style>
<style scoped>
/* Header style */
.email-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.email-header span {
  font-size: 24px;
  margin-right: 10px;
  font-variation-settings: 'FILL' 1;
}

.email-header p {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--neutral-black);
}

/* Email and password info */
.email-info, .password-section {
  margin-bottom: 20px;
}

.label {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #A9A7A7;
  margin-bottom: 8px;
}

.email {
  font-size: 16px;
  font-weight: 500;
  color: var(--neutral-black);
}

.change-password-btn {
  width: 217px;
  background-color: var(--primary);
  color: #FFFFFF;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.change-password-btn:hover {
  background-color: #003366;
}
</style>

<style scoped>
/* Header */
.password-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.password-header span {
  font-size: 24px;
  margin-right: 10px;
  font-variation-settings: 'FILL' 1;
}

.password-header p {
  font-size: 24px;
  font-weight: 600;
  font-weight: bold;
  color: var(--neutral-black);
  margin: 0;
}

/* Form fields */
.input-field {
  margin-bottom: 20px;
}

.input-field label {
  font-weight: 400;
  font-size: 14px;
  color: #a9a7a7;
  margin-bottom: 8px;
  display: block;
}

.input-field input {
  width: 350px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--light-grey);
  background: var(--light-grey);
  font-size: 14px;
  outline: none;
}

.password-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 350px;
}

.password-input-wrapper input {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--light-grey);
  background: var(--light-grey);
  font-size: 14px;
  outline: none;
  padding-right: 40px; 
}

.toggle-visibility {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: var(--dark-grey);
}


/* Buttons */
.button-group {
  display: flex;
  justify-content: flex-end;
  width: 350px;
  gap: 10px;
}

.cancel-btn {
  background-color: #FFFFFF;
  color: var(--primary);
  border: 1px solid var(--mid-grey);
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.update-btn {
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.update-btn:hover {
  background-color: #003366;
}
</style>